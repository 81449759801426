import { Container } from "./styles";

export function TextDivider() {
  return (
    <Container>
      <div className="underline"></div>
      <span className="text">Or</span>
      <div className="underline"></div>
    </Container>
  );
}
