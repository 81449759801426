import { useState } from "react";
import { z } from "zod";
import { format, parseISO, isAfter, isBefore } from "date-fns";
import { useForm } from "storybook/utils/form";
import { DobRow, FormRow } from "../../styles";

const minDate = new Date("1900-01-01");
const maxDate = new Date();

const isDateInRange = (date: Date) => {
  return isAfter(date, minDate) && isBefore(date, maxDate);
};

const userSchema = z.object({
  user: z
    .object({
      firstName: z.string().min(1, { message: "Please enter your first name" }),
      lastName: z.string().min(1, { message: "Please enter your last name" }),
      dateOfBirth: z
        .string()
        .refine(
          (value) => {
            const date = parseISO(value);
            return !isNaN(date.getTime()) && isDateInRange(date);
          },
          {
            message: "Please enter a valid date",
          }
        )
        .transform((value) => {
          const date = parseISO(value);
          return format(date, "yyyy-MM-dd");
        }),
    })
    .required()
    .strict(),
});

type User = z.infer<typeof userSchema>;

type Props = {
  onSubmit: (data: User) => void;
};

export function NameDobForm({ onSubmit }: Props) {
  const [formValues] = useState<User>({
    user: {
      firstName: "",
      lastName: "",
      dateOfBirth: "",
    },
  });

  const { Form, Input, DateOfBirth } = useForm<User>({
    schema: userSchema,
    values: formValues,
    mode: "onChange",
  });

  return (
    <Form onSubmit={onSubmit} disableAutocomplete={true} id="nameDobForm">
      <FormRow>
        <Input
          theme="default"
          label="First Name"
          name="user.firstName"
          hideDirty={true}
          labelPosition="top"
          alignText="left"
          autoComplete="off"
        />
      </FormRow>
      <FormRow>
        <Input
          theme="default"
          label="Last Name"
          name="user.lastName"
          hideDirty={true}
          labelPosition="top"
          alignText="left"
          autoComplete="off"
        />
      </FormRow>
      <DobRow>
        <DateOfBirth
          theme="default"
          name="user.dateOfBirth"
          maxMonthListHeight="30vh"
          disablePortal={false}
        />
      </DobRow>
    </Form>
  );
}
