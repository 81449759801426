// eslint-disable-next-line react-refresh/only-export-components
export enum ErrorType {
  GENERAL = "general",
  AUTH = "auth",
}

if (!import.meta.env.VITE_AUTH0_DOMAIN) {
  throw new Error("Required env variable VITE_AUTH0_DOMAIN missing");
}

if (!import.meta.env.VITE_AUTH0_PATIENTS_CONNECTION) {
  throw new Error("Required env variable AUTH0_PATIENTS_CONNECTION missing");
}

if (!import.meta.env.VITE_AUTH0_PATIENTS_CLIENT_ID) {
  throw new Error("Required env variable AUTH0_PATIENTS_CLIENT_ID missing");
}

if (!import.meta.env.VITE_AUTH0_INSTITUTION_CONNECTION) {
  throw new Error("Required env variable AUTH0_INSTITUTION_CONNECTION missing");
}

if (!import.meta.env.VITE_AUTH0_INSTITUTION_CLIENT_ID) {
  throw new Error("Required env variable AUTH0_INSTITUTION_CLIENT_ID missing");
}

if (!import.meta.env.VITE_PATIENT_PORTAL_SENTRY_DSN) {
  throw new Error(
    "Required env variable VITE_PATIENT_PORTAL_SENTRY_DSN missing"
  );
}

if (!import.meta.env.VITE_PATIENT_PORTAL_SENTRY_ENVIRONMENT) {
  throw new Error(
    "Required env variable VITE_PATIENT_PORTAL_SENTRY_ENVIRONMENT missing"
  );
}

if (!import.meta.env.VITE_PATIENT_PORTAL_SAMPLE_RATE) {
  throw new Error(
    "Required env variable VITE_PATIENT_PORTAL_SAMPLE_RATE missing"
  );
}

if (!import.meta.env.VITE_SEND_EVENTS_TO_SENTRY) {
  throw new Error("Required env variable VITE_SEND_EVENTS_TO_SENTRY missing");
}

if (!import.meta.env.VITE_SPECIALIST_HOSTNAME) {
  throw new Error("Required env variable VITE_SPECIALIST_HOSTNAME missing");
}

if (!import.meta.env.VITE_QUESTIONNAIRE_URL) {
  throw new Error("Required env variable VITE_QUESTIONNAIRE_URL missing");
}

if (!import.meta.env.VITE_ITHACA_HOSTNAME) {
  throw new Error("Required env variable VITE_ITHACA_HOSTNAME missing");
}

if (!import.meta.env.VITE_VIRGIL_USER_URL) {
  throw new Error("Required env variable VITE_VIRGIL_USER_URL missing");
}

if (!import.meta.env.VITE_DELPHI_SURVEY_URL) {
  throw new Error("Required env variable VITE_DELPHI_SURVEY_URL missing");
}

// TODO: Remove once we use Specialist for patient scheduling request
if (!import.meta.env.VITE_NAVIGATOR_HOSTNAME) {
  throw new Error("Required env variable VITE_NAVIGATOR_HOSTNAME missing");
}

if (!import.meta.env.VITE_SEND_EVENTS_TO_PENDO) {
  throw new Error("Required env variable VITE_SEND_EVENTS_TO_PENDO missing");
}

export const AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN;
export const AUTH0_PATIENTS_CONNECTION = import.meta.env
  .VITE_AUTH0_PATIENTS_CONNECTION;
export const AUTH0_PATIENTS_CLIENT_ID = import.meta.env
  .VITE_AUTH0_PATIENTS_CLIENT_ID;
export const AUTH0_INSTITUTION_CONNECTION = import.meta.env
  .VITE_AUTH0_INSTITUTION_CONNECTION;
export const AUTH0_INSTITUTION_CLIENT_ID = import.meta.env
  .VITE_AUTH0_INSTITUTION_CLIENT_ID;
export const SENTRY_DSN = import.meta.env.VITE_PATIENT_PORTAL_SENTRY_DSN;
export const SENTRY_SAMPLE_RATE = Number(
  import.meta.env.VITE_PATIENT_PORTAL_SAMPLE_RATE || 0
);
export const SENTRY_ENVIRONMENT = import.meta.env
  .VITE_PATIENT_PORTAL_SENTRY_ENVIRONMENT;
export const SEND_EVENTS_TO_SENTRY =
  import.meta.env.VITE_SEND_EVENTS_TO_SENTRY === "true";
export const SPECIALIST_HOSTNAME = import.meta.env.VITE_SPECIALIST_HOSTNAME;
export const QUESTIONNAIRE_URL = import.meta.env.VITE_QUESTIONNAIRE_URL;
export const ITHACA_HOSTNAME = import.meta.env.VITE_ITHACA_HOSTNAME;
export const VIRGIL_USER_URL = import.meta.env.VITE_VIRGIL_USER_URL;
export const DELPHI_SURVEY_URL = import.meta.env.VITE_DELPHI_SURVEY_URL;
export const NAVIGATOR_HOSTNAME = import.meta.env.VITE_NAVIGATOR_HOSTNAME;
export const SEND_EVENTS_TO_PENDO = import.meta.env.VITE_SEND_EVENTS_TO_PENDO;
export const PENDO_API_KEY = import.meta.env.VITE_PENDO_API_KEY;
export const ZENDESK_API_KEY = import.meta.env.VITE_ZENDESK_API_KEY;

export const POPULUS_PARTNER_NETWORK = import.meta.env
  .VITE_POPULUS_PARTNER_NETWORK;
export const POPULUS_PARTNER_ID = import.meta.env.VITE_POPULUS_PARTNER_ID;
export const POPULUS_PARTNER_ZONE = import.meta.env.VITE_POPULUS_PARTNER_ZONE;

export const POPULUS_MIN_HEIGHT = 50;
export const POPULUS_MIN_WIDTH = 320;
