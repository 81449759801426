import {
  useMemo,
  useState,
  ReactNode,
  useEffect,
  useContext,
  createContext,
  useRef,
} from "react";
import { SPECIALIST_HOSTNAME } from "env-vars";
import { useAdminContent } from "context/admin-content";
import { PatientResults } from "context/admin-content/types";

type Props = {
  children: ReactNode;
};

enum RiskKey {
  testing = "testing",
  breastMri = "breastMri",
  counseling = "counseling",
  average = "average",
}

type RiskProfileContextType = {
  isLoading: boolean;
  riskResult: PatientResults | undefined;
  riskProfile: any;
};

const RiskProfileContext = createContext<RiskProfileContextType | undefined>(
  undefined
);

export function RiskProfileProvider({ children }: Props) {
  const adminData = useAdminContent();
  const [isLoading, setLoading] = useState(true);
  const [riskResult, setRiskResult] = useState<PatientResults | undefined>();
  const [riskProfile, setRiskProfile] = useState<any>(null);
  const hasFetched = useRef(false);

  useEffect(() => {
    if (!adminData || adminData?.isLoading || hasFetched.current) {
      return;
    }

    const fetchRiskProfile = async () => {
      // After completing an initial survey, "ciq-questionnaire.sessionId" is set by
      // Questionnaire and this is the patient id.
      // After completing a rescreen, we use "ciq_id", which comes from Auth0 upon login.
      const patientIdFromQuestionnaire = localStorage.getItem(
        "ciq-questionnaire.sessionId"
      );
      const patientIdFromAuth0 = localStorage.getItem("ciq_id");
      // If both an auth0 id and sessionId are present, we should prefer the auth0 id
      const patientId = patientIdFromAuth0 || patientIdFromQuestionnaire;

      const idToken = localStorage.getItem("id_token");

      try {
        hasFetched.current = true;

        const response = await fetch(
          `${SPECIALIST_HOSTNAME}/api/v1/people/${patientId}/risk_profiles`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
          }
        );

        if (!response.ok) {
          // TODO: Error behaviour? It's a silent call so maybe do not show error to user

          return;
        }

        const riskProfileRes = await response.json();

        setRiskProfile(riskProfileRes.data);

        const guidances = riskProfileRes.data.attributes;
        const { testingGuidance, mriGuidance, counselingGuidance } = guidances;

        if (testingGuidance.meets_criteria) {
          setRiskResult(adminData.patientResults[RiskKey.testing]);
        } else if (mriGuidance.meets_criteria) {
          setRiskResult(adminData.patientResults[RiskKey.breastMri]);
        } else if (counselingGuidance.meets_criteria) {
          setRiskResult(adminData.patientResults[RiskKey.counseling]);
        } else {
          setRiskResult(adminData.patientResults[RiskKey.average]);
        }
      } catch (error) {
        // What to do for the user here?
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchRiskProfile();
  }, [adminData]);

  const contextProps = useMemo(
    () => ({ riskResult, riskProfile, isLoading }),
    [isLoading, riskResult, riskProfile]
  );

  return (
    <RiskProfileContext.Provider value={contextProps}>
      {children}
    </RiskProfileContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useRiskProfile() {
  const context = useContext(RiskProfileContext);

  if (context === undefined) {
    throw new Error("useRiskProfile must be used within a RiskProfileProvider");
  }
  return context;
}
