// We are not going down the road of typing Asclepeion structures here.
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  KioskLanding,
  KioskRiskSections,
  PatientRiskSections,
  AdminContentContextType,
} from "./types";

function getImageUrl(data: any): string {
  const assets = data.assets;

  if (assets.logo.medium.substring(0, 4) === "http") {
    return assets.logo.medium;
  } else {
    return assets.root + assets.logo.medium;
  }
}

function getAccountType(data: any): string {
  if (data?.account_type) {
    return data.account_type;
  }

  return "Standard";
}

function getKioskLandingData(data: any): KioskLanding {
  const landingPage = data.settings.point_of_care.landing_page;

  return {
    pageTitle: landingPage.title,
    disclaimer: landingPage.disclaimer,
    videoUrl: landingPage.video_url,
    videoEnabled: landingPage.video_enabled,
  };
}

function getKioskResultsData(data: any): KioskRiskSections {
  const pointOfCare = data.settings.point_of_care;

  const testing = pointOfCare.testing;
  const breastMri = pointOfCare.tyrer_cuzick;
  const counseling = pointOfCare.counseling;
  const average = pointOfCare.average;

  return {
    testing: {
      headerImageUrl: testing.header_image_url,
      bodyHtml: testing.body_html,
      videoUrl: testing.video_url,
      videoEnabled: testing.video_enabled,
      patientIdentifierEnabled: testing.show_patient_identifier,
      bioEnabled: testing.bio_enabled,
      bioHtml: testing.bio_html,
      bioImageUrl: testing.bio_image_url,
      showScheduler: testing.show_scheduler,
      showPatientHub: testing.show_patient_hub,
      collectAvailability: testing.collect_availability,
      lockPatientHub: testing.lock_patient_hub,
      lockRestart: testing.lock_restart,
      lockScheduler: testing.lock_scheduler,
      lockCode: testing.lock_code,
    },
    breastMri: {
      headerImageUrl: breastMri.header_image_url,
      bodyHtml: breastMri.body_html,
      videoUrl: breastMri.video_url,
      videoEnabled: breastMri.video_enabled,
      patientIdentifierEnabled: breastMri.show_patient_identifier,
      bioEnabled: breastMri.bio_enabled,
      bioHtml: breastMri.bio_html,
      bioImageUrl: breastMri.bio_image_url,
      showScheduler: breastMri.show_scheduler,
      showPatientHub: breastMri.show_patient_hub,
      collectAvailability: breastMri.collect_availability,
      lockPatientHub: breastMri.lock_patient_hub,
      lockRestart: breastMri.lock_restart,
      lockScheduler: breastMri.lock_scheduler,
      lockCode: breastMri.lock_code,
    },
    counseling: {
      headerImageUrl: counseling.header_image_url,
      bodyHtml: counseling.body_html,
      videoUrl: counseling.video_url,
      videoEnabled: counseling.video_enabled,
      patientIdentifierEnabled: counseling.show_patient_identifier,
      bioEnabled: counseling.bio_enabled,
      bioHtml: counseling.bio_html,
      bioImageUrl: counseling.bio_image_url,
      showScheduler: counseling.show_scheduler,
      showPatientHub: counseling.show_patient_hub,
      collectAvailability: counseling.collect_availability,
      lockPatientHub: counseling.lock_patient_hub,
      lockRestart: counseling.lock_restart,
      lockScheduler: counseling.lock_scheduler,
      lockCode: counseling.lock_code,
    },
    average: {
      headerImageUrl: average.header_image_url,
      bodyHtml: average.body_html,
      videoUrl: average.video_url,
      videoEnabled: average.video_enabled,
      patientIdentifierEnabled: average.show_patient_identifier,
      bioEnabled: average.bio_enabled,
      bioHtml: average.bio_html,
      bioImageUrl: average.bio_image_url,
      showScheduler: average.show_scheduler,
      showPatientHub: average.show_patient_hub,
      collectAvailability: average.collect_availability,
      lockPatientHub: average.lock_patient_hub,
      lockRestart: average.lock_restart,
      lockScheduler: average.lock_scheduler,
      lockCode: average.lock_code,
    },
  };
}

function getPatientResultsData(data: any): PatientRiskSections {
  const offsite = data.settings.offsite;

  const testing = offsite.testing;
  const breastMri = offsite.tyrer_cuzick;
  const counseling = offsite.counseling;
  const average = offsite.average;

  return {
    testing: {
      bodyHtml: testing.body_html,
      videoUrl: testing.video_url,
      videoEnabled: testing.video_enabled,
      showScheduler: testing.show_scheduler,
      callToActionText: testing.call_to_action_text,
    },
    breastMri: {
      bodyHtml: breastMri.body_html,
      videoUrl: breastMri.video_url,
      videoEnabled: breastMri.video_enabled,
      showScheduler: breastMri.show_scheduler,
      callToActionText: breastMri.call_to_action_text,
    },
    counseling: {
      bodyHtml: counseling.body_html,
      videoUrl: counseling.video_url,
      videoEnabled: counseling.video_enabled,
      showScheduler: counseling.show_scheduler,
      callToActionText: counseling.call_to_action_text,
    },
    average: {
      bodyHtml: average.body_html,
      videoUrl: average.video_url,
      videoEnabled: average.video_enabled,
      showScheduler: average.show_scheduler,
      callToActionText: average.call_to_action_text,
    },
  };
}

type AdminDataFiltered = Omit<
  NonNullable<AdminContentContextType>,
  "isLoading" | "error"
>;

export function getAdminData(data: any): AdminDataFiltered {
  const imageUrl = getImageUrl(data);
  const accountType = getAccountType(data);
  const isInitialSurvey =
    data.initial_screening_survey?.type === "initial_screening_survey";
  const initialSurveyToken =
    data.initial_screening_survey?.institution_survey_token;

  return {
    imageUrl,
    accountType,
    isInitialSurvey,
    initialSurveyToken,
    locationName: data.name,
    systemName: data.system_name,
    organizationId: data.organization_id,
    organizationName: data.organization_name,
    landing: getKioskLandingData(data),
    kioskResults: getKioskResultsData(data),
    patientResults: getPatientResultsData(data),
    featureFlags: data.feature_flags || null,
    rescreen: data.settings.rescreen,
  };
}
