import { useState } from "react";
import { z } from "zod";
import { useForm } from "storybook/utils/form";
import { FormRow } from "../../styles";

const codeSchema = z.object({
  user: z
    .object({
      code: z.string().min(1, { message: "Please enter the code" }),
    })
    .required()
    .strict(),
});

type Code = z.infer<typeof codeSchema>;

type Props = {
  onSubmit: (data: Code) => void;
};

export function CodeForm({ onSubmit }: Props) {
  const [formValues] = useState<Code>({
    user: {
      code: "",
    },
  });

  const { Form, Input } = useForm<Code>({
    schema: codeSchema,
    values: formValues,
    mode: "onChange",
  });

  return (
    <Form onSubmit={onSubmit} disableAutocomplete={true} id="codeForm">
      <FormRow>
        <Input
          theme="default"
          name="user.code"
          label="Enter Code"
          hideDirty={true}
          labelPosition="top"
          alignText="left"
          autoComplete="off"
        />
      </FormRow>
    </Form>
  );
}
