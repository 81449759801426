import { useState, useEffect } from "react";
import { SPECIALIST_HOSTNAME } from "env-vars";
import { useAdminContent } from "context/admin-content";
import { Patient } from "./types";

export function usePatient() {
  const adminContent = useAdminContent();
  const [patient, setPatient] = useState<Patient | null>(null);

  useEffect(() => {
    if (adminContent?.isLoading || patient) {
      return;
    }

    (async () => {
      const patientId = localStorage.getItem("ciq_id");
      const idToken = localStorage.getItem("id_token");
  
      const response = await fetch(
        `${SPECIALIST_HOSTNAME}/api/v1/people/${patientId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      if (response.ok) {
        const { person } = await response.json();

        setPatient(person);
      }
    })();

  }, [adminContent?.isLoading, patient]);

  return { patient };
}
