import { useCallback, useState } from "react";
import { Button } from "storybook/components/button";
import { Typography } from "storybook/components/typography";
import { Notification } from "storybook/components/notification";
import { ITHACA_HOSTNAME } from "env-vars";
import { Loading } from "components/loading";
import { RiskSummary } from "components/risk-summary";
import { useRiskReport } from "context/risk-report";
import { createSurveySession } from "helpers/create-survey-session";
import {
  Container,
  PromptContainer,
  ButtonContainer,
  HeadingContainer,
  SummaryContainer,
  NotificationContainer,
} from "./styles";

export function RescreenLanding() {
  const [error, setError] = useState<boolean>(false);
  const [isWaiting, setWaiting] = useState<boolean>(false);
  const {
    isLoading,
    reportData,
    error: reportError,
    refetchRiskReport,
  } = useRiskReport();

  const handleReviewClick = useCallback(async () => {
    setError(false);
    setWaiting(true);

    const patientId = localStorage.getItem("ciq_id");
    const currentUrl = encodeURIComponent(document.location.href.split("?")[0]);

    try {
      const response = await createSurveySession({
        isInitial: false,
        isRemote: true,
      });

      if (!response.ok) {
        console.error("Error initializing survey session");
        setError(true);

        return;
      }

      const json = await response.json();

      if (!json.code) {
        setError(true);

        return;
      }

      // Go to rescreen
      window.location.assign(
        `${ITHACA_HOSTNAME}/patients/${patientId}/encounters/${patientId}/rescreen-questionnaire?code=${json.code}&referrer=${currentUrl}`
      );
    } catch (error) {
      console.error("Error initializing survey session", error);
      setError(true);
    } finally {
      setWaiting(false);
    }
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <HeadingContainer>
        <Typography
          variant="h5"
          component="h1"
          theme="default"
          color="ciqBrandDark"
        >
          You can update your information
        </Typography>
      </HeadingContainer>
      <PromptContainer>
        <Typography variant="body1" theme="default" color="ciqBrandDark">
          To edit or update any changes in your family history please click on
          the link below.
        </Typography>
      </PromptContainer>
      {error && (
        <NotificationContainer>
          <Notification
            fullWidth
            theme="default"
            variant="warning"
            title="Error loading rescreen, please try again."
          />
        </NotificationContainer>
      )}
      <ButtonContainer>
        <Button
          size="large"
          color="default"
          theme="default"
          variant="contained"
          disabled={isWaiting}
          onClick={handleReviewClick}
        >
          Review and Update
        </Button>
      </ButtonContainer>
      <SummaryContainer>
        <Typography
          theme="default"
          variant="h5"
          component="h2"
          color="ciqBrandDark"
        >
          Your personalized risk assessment
        </Typography>
        <RiskSummary
          stacked
          report={reportData}
          error={reportError}
          refetchRiskReport={refetchRiskReport}
        />
      </SummaryContainer>
    </Container>
  );
}
