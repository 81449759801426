import { useCallback, useEffect, useState } from "react";
import { z } from "zod";
import { CircularProgress } from "@material-ui/core";
import { SPECIALIST_HOSTNAME } from "env-vars";
import { useForm } from "storybook/utils/form";
import { Typography } from "storybook/components/typography";
import { Button } from "storybook/components/button";
import { Loading } from "components/loading";
import toast from 'storybook/components/toast';
import { Notification } from "storybook/components/notification";
import { formatPhone } from "helpers";
import {
  MainWrapper,
  FormContainer,
  IsSubmittingWrapper,
  ErrorWrapper,
} from "./styles";
import { GenericErrorMessage, UserInfoUpdateMessage } from "./constants";

const contactSchema = z.object({
  contact: z
    .object({
      address1: z.string().optional().nullable(),
      address2: z.string().optional().nullable(),
      city: z.string().optional().nullable(),
      state: z.string().optional().nullable(),
      zipCode: z.coerce.number().optional().nullable(),
      phoneNumber: z.string().optional().nullable(),
    })
    .required()
    .strict(),
});

type Contact = z.infer<typeof contactSchema>;

const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export function ContactInformation() {
  const [formValues, setFormValues] = useState<Contact>({
    contact: {
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipCode: null,
      phoneNumber: "",
    },
  });

  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(false);

  const patientId = localStorage.getItem("ciq_id");
  const idToken = localStorage.getItem("id_token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await fetch(
          `${SPECIALIST_HOSTNAME}/api/v1/people/${patientId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        const data = await result.json();

        setFormValues({
          contact: {
            address1: data.person.address1,
            address2: data.person.address2,
            city: data.person.city,
            state: data.person.state,
            zipCode: data.person.zip_code,
            phoneNumber: formatPhone(data.person.phone_number),
          },
        });
      } catch (error) {
        setError(true);
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [idToken, patientId]);

  const handleSubmit = useCallback(
    async (formData) => {
      setIsSubmitting(true);
      try {
        const result = await fetch(
          `${SPECIALIST_HOSTNAME}/api/v1/people/${patientId}/contact`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify({
              address1: formData.contact.address1,
              address2: formData.contact.address2,
              city: formData.contact.city,
              state: formData.contact.state,
              zip_code: formData.contact.zipCode,
              phone_number: formData.contact.phoneNumber,
            }),
          }
        );

        if (result.ok) {
          setIsSubmitting(false);
          setError(false);
          toast.success(UserInfoUpdateMessage);
        } else {
          setError(true);
        }
      } catch (error) {
        setError(true);
        console.error(error);
      } finally {
        setIsSubmitting(false);
      }
    },
    [idToken, patientId]
  );

  const { Form, Input, Select } = useForm<Contact>({
    schema: contactSchema,
    values: formValues,
    mode: "onChange",
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <MainWrapper>
      {
        error && (
          <ErrorWrapper>
            <Notification
              theme="default"
              title={GenericErrorMessage}
              variant="error"
              fullWidth={true}
            />
          </ErrorWrapper>
        )
      }
      <Form onSubmit={handleSubmit} disableAutocomplete={true}>
        <FormContainer>
          <div>
            <Typography theme="default" variant="body1" color="black">
              <b>Address</b>
            </Typography>
            <Typography theme="default" variant="body1" color="grey">
              US Address Only
            </Typography>
          </div>

          <Input
            theme="default"
            name="contact.address1"
            label="Address Line 1"
            shrinkLabel={true}
          />
          <Input
            theme="default"
            name="contact.address2"
            label="Address Line 2 (optional)"
            shrinkLabel={true}
          />
          <Input
            theme="default"
            name="contact.city"
            label="City"
            shrinkLabel={true}
          />
          <Select
            theme="default"
            name="contact.state"
            label="State"
            labelPlacement="inside"
          >
            {states.map((state) => (
              <Select.Option key={state} value={state}>
                {state}
              </Select.Option>
            ))}
          </Select>
          <Input
            theme="default"
            name="contact.zipCode"
            label="Zip Code"
            shrinkLabel={true}
          />
        </FormContainer>
        <FormContainer>
          <Typography theme="default" variant="body1" color="black">
            <b>Contact Number</b>
          </Typography>
          <Input
            type="tel"
            theme="default"
            label="Mobile Number"
            name="contact.phoneNumber"
            shrinkLabel={true}
            registerOptions={{
              // onChange ensures the field shows the correct sanitized value
              onChange: (event) =>
                (event.target.value = formatPhone(event.target.value)),
              // setValueAs is also required to ensure the sanitized value is what gets submitted
              // otherwise you could submit something like a1234 even though
              // the field is correctly showing 1234 due to the sequence of events in RHF
              setValueAs: (value) => formatPhone(value),
            }}
          />
        </FormContainer>
        <Button
          theme="default"
          variant="contained"
          color="default"
          type="submit"
          fullWidth={true}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <IsSubmittingWrapper>
              <span>Saving</span>
              <CircularProgress color="inherit" />
            </IsSubmittingWrapper>
          ) : (
            "Save"
          )}
        </Button>
      </Form>
    </MainWrapper>
  );
}
