import styled from "styled-components";
import { defaultTheme } from "storybook/themes/default";

export const CampaignImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const AdWrapper = styled.div.withConfig({
  shouldForwardProp: () => true,
})`
  &.with-background {
    background: ${defaultTheme.palette.background.default};
  }

  > iframe,
  > div {
    margin: 0 auto;
  }
`;
