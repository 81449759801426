import styled from "styled-components";

export const ButtonContainer = styled.div`
  flex-direction: column;
  display: flex;
  gap: 16px;

  && {
    button {
      text-transform: capitalize;
    }
  }
`;
