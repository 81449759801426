import React from "react";

import { MainContainer, LinkWrapper, SettingsLink } from "./styles";

import { Typography } from "storybook/components/typography";
import { ChevronRight } from "@material-ui/icons";

export function Settings() {
  return (
    <MainContainer data-testid="Settings">
      <div>
        <Typography theme="default" variant="body1" color="ciqBrandDark">
          <b>Personal Information</b>
        </Typography>
        <LinkWrapper>
          <SettingsLink to="settings/personal-information">
            Update Personal Information
            <ChevronRight />
          </SettingsLink>
        </LinkWrapper>
        <LinkWrapper>
          <SettingsLink to="settings/contact-information">
            Contact Information
            <ChevronRight />
          </SettingsLink>
        </LinkWrapper>
      </div>
      <div>
        <Typography theme="default" variant="body1" color="ciqBrandDark">
          <b>Privacy</b>
        </Typography>
        <LinkWrapper>
          <SettingsLink to="settings/consent">
            Data Consent
            <ChevronRight />
          </SettingsLink>
        </LinkWrapper>
      </div>
    </MainContainer>
  );
}
