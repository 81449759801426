import styled from "styled-components";
import {
  ToastContainer as ToastContainerBase,
  ToastContainerProps,
} from "react-toastify";

export const ToastContainer = styled(ToastContainerBase).attrs({
  toastClassName: "toast",
} as ToastContainerProps)`
  && .toast {
    min-height: auto;
    padding: 0;
  }
`;
