import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;

  .underline {
    flex: 1;
    width: 100%;
    border-bottom: 1px solid #c4c4c4;
  }

  .text {
    margin: 0 20px;
  }
`;
