// @ts-ignore package has no types
import HtmlParser from "react-html-parser";
import { Typography } from "storybook/components/typography";
import { Accordion } from "storybook/components/accordion";
import { RiskSummary } from "components/risk-summary";
import { useRiskReport } from "context/risk-report";
import { ReportContainer } from "../../../styles";
import { SectionContent } from "../../styles";

const pagesToExclude = ["page2", "page3"];

export function RiskResultsSection() {
  const { error, reportHtml, reportData, refetchRiskReport } = useRiskReport();

  const Report = () => (
    <ReportContainer role="article">
      {HtmlParser(reportHtml, {
        transform: (node: any) =>
          pagesToExclude.includes(node.attribs?.id) ? null : undefined,
      })}
    </ReportContainer>
  );

  return (
    <SectionContent>
      <Typography theme="default" variant="h5" component="h2" color="black">
        Your personalized risk assessment
      </Typography>
      <RiskSummary
        error={error}
        report={reportData}
        refetchRiskReport={refetchRiskReport}
      />
      <Accordion
        theme="default"
        title="Report Preview"
        previewContent={<Report />}
      >
        <Report />
      </Accordion>
    </SectionContent>
  );
}
