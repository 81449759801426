import { Button } from "storybook/components/button";
import { TextDivider } from "components/text-divider";
import { ButtonContainer } from "./styles";

interface FormSelectionProps {
  nameDobClick: () => void;
  codeClick: () => void;
}

export function FormSelection({ nameDobClick, codeClick }: FormSelectionProps) {
  return (
    <ButtonContainer>
      <Button
        theme="default"
        variant="outlined"
        size="large"
        onClick={nameDobClick}
      >
        Sign in via name and date of birth
      </Button>
      <TextDivider />
      <Button
        theme="default"
        variant="outlined"
        size="large"
        onClick={codeClick}
      >
        Sign in via code
      </Button>
    </ButtonContainer>
  );
}
