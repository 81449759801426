import styled from "styled-components";
import { Link } from "react-router-dom";
import { tablet, mobile } from "styles/breakpoints";
import { IconButton } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { Typography } from "storybook/components/typography";

export const Container = styled.nav<{
  $scrollbarWidth: number;
  $scrollbarVisible: boolean;
  $isBackNav?: boolean;
}>`
  background-color: #fff;
  padding-top: 10px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ $scrollbarVisible, $scrollbarWidth }) =>
    $scrollbarVisible ? 0 : $scrollbarWidth}px;

  ${({ $isBackNav }) =>
    $isBackNav &&
    `
      margin-right: 0;
      padding-bottom: 10px;
      box-shadow: 0px 2px 10px 0px #00000029;
    `}
`;

export const NavContent = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;

  > button {
    &:hover {
      background-color: transparent;
    }
  }
`;

export const NavSectionLeft = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;

  > img {
    margin-top: 6px;
    margin-right: 40px;
  }

  @media (min-width: ${mobile}px) {
    padding-left: 25px;
  }
`;

export const MenuButtonIcon = styled(IconButton)`
  margin-top: -8px;
`;

export const HomeMenuItem = styled(MenuItem)`
  &.home {
    @media (min-width: ${tablet}px) {
      display: none;
    }
  }
`;

export const NavMenuItemLink = styled(Link)`
  font-size: 16px;
  text-decoration: none;
  color: inherit;
`;

export const NavMenuItem = styled.span`
  font-size: 14px;
  display: flex;
  align-items: center;
`;

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 1200px;
  position: absolute;
  right: 8px;

  button {
    border-radius: 8px;
  }

  @media (min-width: ${mobile}px) {
    right: 25px;
  }
`;

export const AccountText = styled.span`
  color: #48495c;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-left: 5px;
  display: none;

  @media (min-width: ${mobile}px) {
    display: flex;
  }
`;

export const HeaderText = styled(Typography).attrs({
  // @ts-ignore cannot resolve against default theme
  theme: "default",
})`
  margin: 0 auto;
`;
