import {
  useMemo,
  useState,
  useEffect,
  useContext,
  ReactElement,
  createContext,
} from "react";
import { useAdminContent, useFeatureFlags } from "context/admin-content";
import { usePatientAds } from "./use-patient-ads";
import { ContentType, AdvertContextType } from "./types";

const AdvertContext = createContext<AdvertContextType>({
  contentType: null,
  cdpCampaign: null,
  populusAttributes: null,
});

type Props = {
  children: ReactElement;
};

export function AdvertProvider({ children }: Props) {
  const adminContent = useAdminContent();
  const { campaignContentEnabled, targetedPatientContent } = useFeatureFlags();
  const { cdpCampaign, populusAttributes } = usePatientAds();
  const [contentType, setContentType] = useState<ContentType | null>(null);

  const showCDP = campaignContentEnabled;
  const showPopulus = !showCDP && targetedPatientContent;

  useEffect(() => {
    if (adminContent?.isLoading) {
      return;
    }

    if (showCDP) {
      setContentType(ContentType.CDP);
      return;
    }
    
    if (showPopulus) {
      // Populus is handled outside of React
      setContentType(ContentType.POPULUS);
      return;
    }
  }, [
    showCDP,
    showPopulus,
    adminContent?.isLoading,
  ]);

  const contextProps = useMemo(
    () => ({
      cdpCampaign,
      contentType,
      populusAttributes,
    }),
    [cdpCampaign, contentType, populusAttributes]
  );

  return (
    <AdvertContext.Provider value={contextProps}>
      {children}
    </AdvertContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useAdvert() {
  const advertContext = useContext(AdvertContext);

  if (!advertContext) {
    throw new Error("useAdvert hook must only be used within a AdvertProvider");
  }

  return advertContext;
}
