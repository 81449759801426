import { useState, useEffect, useCallback, useRef, useMemo } from "react";
import {
  POPULUS_PARTNER_NETWORK,
  POPULUS_PARTNER_ID,
  POPULUS_PARTNER_ZONE,
  SPECIALIST_HOSTNAME,
} from "env-vars";
import { useAdminContent } from "context/admin-content";
import { PopulusAttributes, CDPCampaign } from "./types";
import { usePatient } from "./use-patient";

export function usePatientAds() {
  const adminContent = useAdminContent();
  const { patient } = usePatient();
  const fetchedRef = useRef(false);
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [cdpCampaign, setCDPCampaign] = useState<CDPCampaign | null>(null);
  const [populusAttributes, setPopulusAttributes] =
    useState<PopulusAttributes | null>(null);

  const ageRange = useMemo(() => {
    if (!patient?.date_of_birth) {
      return 0;
    }

    // Do not need super precision here because:
    // 1) We are calculating in order to determine an age range category
    // 2) The use case is custom content targeting
    const dob = Date.parse(patient?.date_of_birth as string);
    const diff_ms = Date.now() - dob;
    const age_dt = new Date(diff_ms);
    const age = Math.abs(age_dt.getUTCFullYear() - 1970);

    if (age >= 65) {
      return 7;
    }

    if (age >= 18) {
      return Math.floor((age - 15) / 10) + 2;
    }

    return 0;
  }, [patient?.date_of_birth]);

  const fetchAds = useCallback(async () => {
    setLoading(true);

    const patientId = localStorage.getItem("ciq_id");
    const idToken = localStorage.getItem("id_token");
    const locationId = localStorage.getItem("location_id");

    try {
      const response = await fetch(
        `${SPECIALIST_HOSTNAME}/api/v1/people/${patientId}/ads`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      if (!response.ok) {
        setError(true);
        return;
      }

      const { campaign, keywords } = await response.json();

      setCDPCampaign(campaign);
      setPopulusAttributes({
        zone: POPULUS_PARTNER_ZONE,
        debug: POPULUS_PARTNER_ZONE === "dev" ? "1" : "0",
        network: POPULUS_PARTNER_NETWORK,
        partner: POPULUS_PARTNER_ID,
        keywords: keywords.toString(),
        "visit-id": Math.random().toString().slice(2, 7),
        "age-range": ageRange,
        "visit-stage": "during",
        "partner-subacct": locationId,
      });
    } catch (error) {
      setError(true);
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [ageRange]);

  useEffect(() => {
    if (
      adminContent &&
      !adminContent.isLoading &&
      !fetchedRef.current &&
      patient
    ) {
      fetchedRef.current = true;
      fetchAds();
    }
  }, [adminContent, patient, fetchAds]);

  return useMemo(
    () => ({
      cdpCampaign,
      populusAttributes,
      isLoading,
      error,
    }),
    [cdpCampaign, populusAttributes, isLoading, error]
  );
}
