import { useRef } from "react";
import { useAdvert } from "context/advert";
import { CDPCampaignImage, ContentType } from "context/advert/types";
import { desktop, tablet } from "styles/breakpoints";
import { renderPopulus } from "./render-populus";
import { AdWrapper, CampaignImage } from "./styles";
import { POPULUS_MIN_HEIGHT, POPULUS_MIN_WIDTH } from "env-vars";
import { useMediaQuery } from "@material-ui/core";

type Props = {
  ciqClass: string;
  ciqCanvas: string;
  ciqInventoryClass: string;
  ciqTargetedContent: string;
  ciqShowTargetedContent: string;
};

const mobileMaxImageSize = { width: 350, height: 350 };
const tabletMaxImageSize = { width: 160, height: 600 };
const desktopMaxImageSize = { width: 300, height: 600 };

const groupByLayout = (images: CDPCampaignImage[]) => (images ?? []).reduce((acc, image) => {
  const isMobileSize = image.width <= mobileMaxImageSize.width && image.height <= mobileMaxImageSize.height;
  const isTabletSize = !isMobileSize && image.width <= tabletMaxImageSize.width && image.height <= tabletMaxImageSize.height;
  const isDesktopSize = !isMobileSize && !isTabletSize;

  if (isTabletSize) {
    acc.tablet.push(image);
  }
  if (isMobileSize) {
    acc.mobile.push(image);
  }
  if (isDesktopSize) {
    acc.desktop.push(image);
  }
  return acc;
}, {
  mobile: [],
  tablet: [],
  desktop: []
} as {
  mobile: CDPCampaignImage[],
  tablet: CDPCampaignImage[],
  desktop: CDPCampaignImage[]
});

export function Advert({
  ciqClass,
  ciqCanvas,
  ciqInventoryClass,
  ciqTargetedContent,
  ciqShowTargetedContent,
}: Props) {
  const isTabletView = useMediaQuery(`(min-width:${tablet}px) and (max-width:${desktop}px)`);
  const isDesktopView = useMediaQuery(`(min-width:${desktop}px)`);
  const { contentType, cdpCampaign, populusAttributes } = useAdvert();
  const adsRef = useRef<HTMLDivElement | null>(null);
  const imageSize = isDesktopView ? desktopMaxImageSize : mobileMaxImageSize;
  const { height, width } = isTabletView ? tabletMaxImageSize : imageSize;  

  if (contentType === ContentType.CDP && cdpCampaign && Array.isArray(cdpCampaign.images)) {
    const images = groupByLayout(cdpCampaign.images);
    const defaultImageUrl = (images.mobile[0] || images.tablet[0] || images.desktop[0])?.url;

    return (
      <AdWrapper
        id={ciqCanvas}
        className={ciqClass}
        ciq-canvas={ciqCanvas}
        ciq-width={`${width}px`}
        ciq-height={`${height}px`}
        ciq-targeted-content={ciqTargetedContent}
        ciq-inventory-class={ciqInventoryClass}
        ciq-show-targeted-content={ciqShowTargetedContent}
      >
        <CampaignImage>
          <picture>
            {images.mobile.map((image) => (
              <source 
                key={image.url}
                media={`(max-width:${tablet - 1}px) and (min-width:${image.width}px)`}
                srcSet={image.url}
              />
            ))}
            {images.tablet.map((image) => (
              <source
                key={image.url}
                media={`(min-width:${tablet}px) and (max-width:${desktop}px) and (min-width:${image.width}px)`}
                srcSet={image.url}
              />
            ))}
            {images.desktop.map((image) => (
              <source
                key={image.url}
                media={`(min-width:${desktop}px) and (min-width:${image.width}px)`}
                srcSet={image.url}
              />
            ))}
            <img src={defaultImageUrl} style={{ width: 'auto' }} alt="campaign" />
          </picture>
        </CampaignImage>
      </AdWrapper>
    );
  }

  if (contentType === ContentType.POPULUS && populusAttributes) {
    const attributes = {
      ...populusAttributes,
      height: Math.max(POPULUS_MIN_HEIGHT, height),
      width: Math.max(POPULUS_MIN_WIDTH, width),
      "inventory-class": ciqInventoryClass
    };

    return (
      <AdWrapper
        id={ciqCanvas}
        className={ciqClass}
        ciq-canvas={ciqCanvas}
        ciq-width={`${width}px`}
        ciq-height={`${height}px`}
        ciq-inventory-class={ciqInventoryClass}
        ciq-targeted-content={ciqTargetedContent}
        ciq-show-targeted-content={ciqShowTargetedContent}
        ref={(node) => {
          if (!adsRef.current) {
            renderPopulus({
              targetElement: node,
              populusAttributes: attributes
            });
            adsRef.current = node;
          }
        }}
      />
    );
  }

  return null;
}
