export enum ContentType {
  CDP = "CDP",
  POPULUS = "POPULUS",
}

export type PopulusAttributes = {
  zone: string;
  debug: string;
  network: string;
  partner: string;
  keywords: string;
  "visit-id": string;
  "age-range": number;
  "visit-stage": "during";
  "partner-subacct": string | null;
};

export type CDPCampaignImage = {
  url: string;
  height: number;
  width: number;
};

export type CDPCampaign = {
  id: string;
  name: string;
  start_date: string;
  end_date: string;
  keywords: string[];
  images: Array<CDPCampaignImage>;
};

export type Patient = {
  address1: string | null;
  address2: string | null;
  city: string | null;
  date_of_birth: string | null;
  first_name: string | null;
  last_name: string | null;
  phone_number: string | null;
  state: string | null;
  zip_code: string | null;
};

export type AdvertContextType = {
  contentType: ContentType | null;
  cdpCampaign: CDPCampaign | null;
  populusAttributes: PopulusAttributes | null;
};
