import { POPULUS_PARTNER_ZONE } from "env-vars";
import { PopulusAttributes } from "context/advert/types";

const adPartnerZone = POPULUS_PARTNER_ZONE;

type Props = {
  targetElement: HTMLElement | null;
  populusAttributes: PopulusAttributes & { height: number; width: number, 'inventory-class': string };
};

export function renderPopulus({ targetElement, populusAttributes }: Props) {
  // if there is no target element or
  // if there is already an iframe in the target element, do not render the ad
  if (
    !targetElement ||
    targetElement.getElementsByTagName("iframe").length > 0
  ) {
    return;
  }

  // @ts-ignore $pop
  const adObject = window.$pop.render(targetElement, populusAttributes);
  if (adObject && adObject.status === "") {
    // eslint-disable-next-line no-console
    console.info(
      "Successfully initialized content, adding event listeners",
      adObject
    );

    adObject.addEventListener("noAds", () => {
      // No ad could be shown - late stage - during rendering
      // eslint-disable-next-line no-console
      console.debug("Content display error: no content available");
      if (adPartnerZone === "dev" && targetElement)
        targetElement.innerHTML = "<h1>No ads available</h1>";
    });
  } else {
    // No ad could be shown - early stage - during initialization
    console.error("Content display initialization error", adObject);
  }
}
