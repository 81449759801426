import {
  toast as toastAPI,
  type ToastContainerProps,
  ToastOptions,
} from "react-toastify";
import {
  Notification,
  NotificationType,
} from "storybook/components/notification";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "./styles";

const defaultProps: Partial<ToastContainerProps> = {
  position: "top-right",
  autoClose: 5000,
  enableMultiContainer: false,
  closeButton: false,
  newestOnTop: false,
  rtl: false,
  draggable: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
};

export type ToasterProps = Pick<ToastContainerProps, "position"> &
  Pick<NotificationType, "theme">;

export const Toaster = (props: ToasterProps) => (
  <ToastContainer {...defaultProps} {...props} />
);

const toast = (message: string, toastProps?: ToastOptions) =>
  toastAPI(
    <Notification
      theme="default"
      title={message}
      variant="info"
      fullWidth={true}
    />,
    toastProps
  );

toast.success = (message: string, toastProps?: ToastOptions) =>
  toastAPI(
    <Notification
      theme="default"
      title={message}
      variant="success"
      fullWidth={true}
    />,
    toastProps
  );

toast.warning = (message: string, toastProps?: ToastOptions) =>
  toastAPI(
    <Notification
      theme="default"
      title={message}
      variant="warning"
      fullWidth={true}
    />,
    toastProps
  );

toast.error = (message: string, toastProps?: ToastOptions) =>
  toastAPI(
    <Notification
      theme="default"
      title={message}
      variant="error"
      fullWidth={true}
    />,
    toastProps
  );

export default toast;
