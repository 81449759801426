import styled, { createGlobalStyle } from "styled-components";
import { mobile } from "styles/breakpoints";

export const Container = styled.div`
  position: relative;

  .start-assessment {
    min-height: 100vh;
  }
`;

export const Header = styled.div`
  margin: 0 auto;
  max-width: 1728px;
`;

export const LogoContainer = styled.div`
  padding: 10px;
  position: absolute;

  img {
    width: 150px;
  }
`;

export const FormRow = styled.div<{
  $last?: boolean;
}>`
  height: 100px;
`;

export const DobRow = styled.div`
  height: 130px;
`;

export const ButtonContainer = styled.div`
  margin-left: 0 !important;

  button {
    height: 44px;
    width: 188px;
    font-size: 18px;
    text-transform: capitalize;
  }
`;

export const SignInContainer = styled(ButtonContainer)`
  margin-top: 30px;
`;

export const QuestionnaireButton = styled.div`
  margin: 20px 0 0 0 !important;
  display: flex;
  width: 100%;
  justify-content: end;

  && {
    button {
      width: 100%;
      font-size: 18px;
      text-transform: none;
    }
  }

  @media (min-width: ${mobile}px) {
    && {
      button {
        width: 50%;
      }
    }
  }
`;

export const HeadingContainer = styled.div`
  && {
    font-size: 24px;
  }
`;

export const TextWrapper = styled.span`
  font-size: 24px;
`;

export const StyledModal = styled.div`
  border: 5px solid red;
`;

export const GlobalModalStyles = createGlobalStyle`
  [role="presentation"] {
    .MuiDialog-paper {
      overflow: auto;
      padding: 2rem;
      margin: 0;
    }

    .close-btn {
      margin-right: 0;
    }
  }

  .MuiPaper-root {
    overflow: visible;
    max-height: 100vh !important;
  }

  .MuiDialogTitle-root {
    h2 {
      padding-right: 50px;
      color: #333 !important;
      margin-top: -10px;
    }
  }

  .MuiDialogActions-root {
    flex-direction: column;
    margin-top: 0 !important;
  }
`;

export const Banner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fbfae0;
  border: 1px solid #8a8623;
  border-radius: 4px;
  font-size: 16px;
  color: #5a560d;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  margin: 16px 0;
  padding: 10px;
  word-break: break-word;
`;

export const CodeButtons = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 20px;

  button {
    flex: 1;
    font-size: 18px;
    text-transform: none;
  }

  button:first-child {
    margin: 0 0 10px 0;
  }

  @media (min-width: ${mobile}px) {
    flex-direction: row;

    button:first-child {
      width: auto;
      margin: 0;
    }
  }
`;

export const CodePrompt = styled.div`
  margin: 20px 0 20px 0;
`;

export const CopyContainer = styled.div`
  margin-bottom: 24px;

  && {
    p {
      word-break: break-word;
    }
  }
`;
