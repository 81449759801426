export function WaitingGraphic() {
  return (
    <svg
      width="129"
      height="125"
      viewBox="0 0 129 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.918 28.1797C13.5746 24.6311 16.5931 21.3271 19.9457 18.322C32.4047 7.15201 48.4479 1.00082 65.1194 1.00082C67.456 1.00082 69.825 1.12377 72.161 1.36513C76.9578 1.86144 81.5871 2.85018 86.0041 4.27441"
        stroke="#4569B9"
        strokeWidth="1.41503"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="6.47 6.47"
      />
      <path
        d="M52.8057 123.962C50.8222 123.513 48.8519 122.973 46.9017 122.341C44.6788 121.621 42.463 120.774 40.3157 119.825C25.324 113.195 13.4841 101.637 6.53453 86.9754"
        stroke="#4569B9"
        strokeWidth="1.41503"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="6.47 6.47"
      />
      <path
        d="M127.678 58.2679C128.108 64.2579 127.741 70.4025 126.491 76.5689C123.661 90.5174 116.579 103.092 106.275 112.679"
        stroke="#4569B9"
        strokeWidth="1.41503"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="6.47 6.47"
      />
      <path
        d="M15.1865 56.634C18.0179 29.274 42.493 9.38971 69.8524 12.2214C97.2125 15.053 117.097 39.5277 114.265 66.8871C111.434 94.2472 86.9588 114.131 59.5987 111.3C32.2393 108.468 12.355 83.9935 15.1865 56.634Z"
        fill="#FFE7A1"
      />
      <path
        d="M36.5737 41.2286C32.3862 46.9914 29.918 54.0853 29.918 61.7506C29.918 81.0468 45.5646 96.6934 64.8608 96.6934C70.5071 96.6934 75.8427 95.3511 80.5683 92.9717"
        stroke="#2869D1"
        strokeWidth="2.21859"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.038 83.3818C96.8024 77.4083 99.803 69.9871 99.803 61.7506C99.803 42.4544 84.1564 26.8078 64.8602 26.8078C58.6482 26.8078 52.8133 28.4273 47.7549 31.2727"
        stroke="#2869D1"
        strokeWidth="2.21859"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.7833 34.0174L32.1357 36.7907L41.0101 45.665L43.7833 34.0174Z"
        stroke="#2869D1"
        strokeWidth="2.21859"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.8281 90.5918L96.4757 87.8186L87.6014 78.9442L84.8281 90.5918Z"
        stroke="#2869D1"
        strokeWidth="2.21859"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.5358 53.6904L61.4546 39.2807"
        stroke="#2869D1"
        strokeWidth="2.21859"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.2658 85.3291L43.1846 70.9194"
        stroke="#2869D1"
        strokeWidth="2.21859"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.7398 60.5689C60.7398 60.5689 61.3333 60.4081 61.4997 60.3637C61.4553 60.1973 61.2944 59.6038 61.2944 59.6038C60.0797 55.0613 58.8817 49.9031 59.4363 45.0832C59.8634 41.3504 62.3539 37.8007 62.3539 37.8007L64.5725 33.957L89.5537 48.3778L87.3351 52.2215C87.3351 52.2215 85.5104 56.1539 82.4876 58.3947C78.5884 61.2844 73.5245 62.8263 68.9819 64.041L68.222 64.2462L68.4273 65.0061C69.6419 69.5487 70.84 74.7069 70.2853 79.5268C69.8583 83.2596 67.3678 86.8093 67.3678 86.8093L65.1492 90.6475L40.168 76.2266L42.3866 72.3885C42.3866 72.3885 44.2113 68.456 47.2341 66.2152C51.1333 63.3255 56.1972 61.7836 60.7398 60.5689Z"
        stroke="#4A4D5E"
        strokeWidth="2.21859"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
