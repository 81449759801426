import { useState, useCallback } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Button } from "storybook/components/button";
import { Modal } from "storybook/components/modal";
import { Typography } from "storybook/components/typography";
import { useAdminContent } from "context/admin-content";
import { useKioskAuth } from "context/kiosk-auth";
import { ITHACA_HOSTNAME, SPECIALIST_HOSTNAME } from "env-vars";
import { clearQuestionnaire, openQuestionnaire } from "helpers";
import { createSurveySession } from "helpers/create-survey-session";
import { CodeForm } from "./forms/code-form";
import { NameDobForm } from "./forms/name-dob-form";
import { FormSelection } from "./forms/form-selection";
import {
  Banner,
  CodeButtons,
  TextWrapper,
  CopyContainer,
  GlobalModalStyles,
  QuestionnaireButton,
} from "../styles";

enum ErrorType {
  GENERAL = "GENERAL",
  NOT_FOUND = "NOT_FOUND",
  NO_INITIAL_SURVEY = "NO_INITIAL_SURVEY",
  NOT_FOUND_INTEGRATED = "NOT_FOUND_INTEGRATED",
  NOT_FOUND_NON_INTEGRATED = "NOT_FOUND_NON_INTEGRATED",
  MULTIPLE_FOUND_INTEGRATED = "MULTIPLE_FOUND_INTEGRATED",
  MULTIPLE_FOUND_NON_INTEGRATED = "MULTIPLE_FOUND_NON_INTEGRATED",
}

type Props = {
  onClose: () => void;
  disableCodePrompt: boolean;
};

enum ContentType {
  CODE = "CODE",
  NAME_DOB = "NAME_DOB",
  FORM_SELECTION = "FORM_SELECTION",
}

const ModalContent: Record<ContentType, React.ElementType> = {
  [ContentType.CODE]: CodeForm,
  [ContentType.NAME_DOB]: NameDobForm,
  [ContentType.FORM_SELECTION]: FormSelection,
};

export function NameDobCodeModal({ onClose, disableCodePrompt }: Props) {
  const adminData = useAdminContent();
  const { isIntegrated, logout } = useKioskAuth();
  const [error, setError] = useState<ErrorType | null>(null);
  const [contentType, setContentType] = useState<keyof typeof ModalContent>(
    () =>
      disableCodePrompt ? ContentType.NAME_DOB : ContentType.FORM_SELECTION
  );
  const [isSubmitting, setSubmitting] = useState(false);
  const locationName = adminData?.locationName;
  const initialSurveyToken = adminData?.initialSurveyToken;

  const handleSubmit = useCallback(
    async (data: any) => {
      setError(null);
      setSubmitting(true);

      const jwt = localStorage.getItem("id_token");

      const { user } = data;

      if (user.code) {
        const response = await fetch(
          `${SPECIALIST_HOSTNAME}/api/v1/survey_sessions/lock`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwt}`,
            },
            body: JSON.stringify({
              code: user.code,
            }),
          }
        );

        if (!response.ok) {
          if (response.status === 404) {
            setError(ErrorType.NOT_FOUND);
          } else if (response.status === 401) {
            logout();
          } else {
            setError(ErrorType.GENERAL);
          }
          setSubmitting(false);
          return;
        }

        const sessionData = await response.json();

        if (!sessionData.survey_token) {
          setError(ErrorType.GENERAL);

          setSubmitting(false);
          return;
        }

        if (sessionData.can_rescreen) {
          const currentUrl = encodeURIComponent(
            document.location.href.split("?")[0]
          );
          const { patient_id: patientId } = sessionData;
          const rescreenUrl = `${ITHACA_HOSTNAME}/patients/${patientId}/encounters/${patientId}/rescreen-questionnaire?code=${user.code}&referrer=${currentUrl}`;

          window.open(rescreenUrl, "_self");
        } else {
          const currentUrl = document.location.href.split("?")[0];
          const redirectUrl = `${currentUrl}?complete=true`;
          const queryParams = {
            survey_code: user.code,
            redirectUrl: encodeURIComponent(redirectUrl),
            restartUrl: encodeURIComponent(currentUrl),
          };

          openQuestionnaire({
            queryParams,
            newTab: false,
            token: sessionData.survey_token,
            skipLandingPage: true,
          });
        }
      } else {
        const findResponse = await fetch(
          `${SPECIALIST_HOSTNAME}/api/v1/people/find`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwt}`,
            },
            body: JSON.stringify({
              date_of_birth: user.dateOfBirth,
              first_name: user.firstName,
              last_name: user.lastName,
            }),
          }
        );

        if (!findResponse.ok) {
          if (findResponse.status === 404) {
            setError(
              isIntegrated
                ? ErrorType.NOT_FOUND_INTEGRATED
                : ErrorType.NOT_FOUND_NON_INTEGRATED
            );
          } else if (findResponse.status === 403) {
            setError(
              isIntegrated
                ? ErrorType.MULTIPLE_FOUND_INTEGRATED
                : ErrorType.MULTIPLE_FOUND_NON_INTEGRATED
            );
          } else if (findResponse.status === 401) {
            logout();
            return;
          } else {
            setError(ErrorType.GENERAL);
          }

          if (!disableCodePrompt) {
            setContentType(ContentType.FORM_SELECTION);
          }

          setSubmitting(false);
          return;
        }

        const findPatientData = await findResponse.json();
        const canRescreen = findPatientData.can_rescreen;
        const patientId = findPatientData.person_id;

        const sessionResponse = await createSurveySession({
          isInitial: !canRescreen,
          personId: patientId,
          isRemote: false,
        });

        if (!sessionResponse.ok) {
          if (sessionResponse.status === 401) {
            logout();
            return;
          }
          setError(ErrorType.GENERAL);
          setSubmitting(false);
          return;
        }

        const sessionData = await sessionResponse.json();
        const { survey_type, code, survey_token } = sessionData;

        if (survey_type === "in_person_rescreen" && code) {
          const currentUrl = encodeURIComponent(
            document.location.href.split("?")[0]
          );
          // In person rescreen, redirect to ithaca rescreen wizard
          const rescreenUrl = `${ITHACA_HOSTNAME}/patients/${patientId}/encounters/${patientId}/rescreen-questionnaire?code=${code}&referrer=${currentUrl}`;

          window.open(rescreenUrl, "_self");
        } else if (survey_type === "code" && survey_token) {
          // Open questionnaire with survey_token
          const currentUrl = document.location.href.split("?")[0];
          const redirectUrl = `${currentUrl}?complete=true`;
          const restartUrl = currentUrl;
          const queryParams = {
            redirectUrl: encodeURIComponent(redirectUrl),
            restartUrl: encodeURIComponent(restartUrl),
          };

          localStorage.setItem("surveyCode", code);

          openQuestionnaire({
            queryParams,
            newTab: false,
            token: survey_token,
            skipLandingPage: true,
          });

          return;
        } else {
          setError(ErrorType.NO_INITIAL_SURVEY);
        }
      }
      setSubmitting(false);
    },
    [disableCodePrompt, isIntegrated, logout]
  );

  const delegateFormSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      if (contentType === ContentType.CODE) {
        const form = document.querySelector("form#codeForm");
        form?.dispatchEvent(
          new Event("submit", { bubbles: true, cancelable: true })
        );
      } else if (contentType === ContentType.NAME_DOB) {
        const form = document.querySelector("form#nameDobForm");
        form?.dispatchEvent(
          new Event("submit", { bubbles: true, cancelable: true })
        );
      }
    },
    [contentType]
  );

  const handleNameDobClick = useCallback(() => {
    setError(null);
    setContentType(ContentType.NAME_DOB);
  }, []);

  const handleCodeClick = useCallback(() => {
    setError(null);
    setContentType(ContentType.CODE);
  }, []);

  const handleBackClick = useCallback(() => {
    setError(null);

    if (disableCodePrompt) {
      onClose();
    } else {
      setContentType(ContentType.FORM_SELECTION);
    }
  }, [disableCodePrompt, onClose]);

  const startQuestionnaire = useCallback(() => {
    clearQuestionnaire();

    if (initialSurveyToken) {
      const currentUrl = document.location.href.split("?")[0];
      const redirectUrl = `${currentUrl}?complete=true`;
      const restartUrl = currentUrl;
      const queryParams = {
        redirectUrl: encodeURIComponent(redirectUrl),
        restartUrl: encodeURIComponent(restartUrl),
      };

      openQuestionnaire({
        queryParams,
        newTab: false,
        token: initialSurveyToken,
        skipLandingPage: true,
      });
    } else {
      setError(ErrorType.NO_INITIAL_SURVEY);
    }
  }, [initialSurveyToken]);

  const showStartQuestionnaireBtn =
    !isIntegrated &&
    (error === ErrorType.NOT_FOUND_NON_INTEGRATED ||
      error === ErrorType.MULTIPLE_FOUND_NON_INTEGRATED);

  const Content = ModalContent[contentType];

  return (
    <>
      <GlobalModalStyles />
      <Modal
        open={true}
        width={600}
        theme="default"
        onClose={onClose}
        disableBackdropClick={true}
      >
        <Modal.Head alignTitle="left">
          <TextWrapper>Let's find your patient record</TextWrapper>
        </Modal.Head>
        <Modal.Body>
          {error === ErrorType.NOT_FOUND_INTEGRATED && (
            <Banner role="alert">
              We couldn't find your patient record in our system. Please try
              again or ask your healthcare team for help.
            </Banner>
          )}
          {(error === ErrorType.NOT_FOUND_NON_INTEGRATED ||
            error === ErrorType.MULTIPLE_FOUND_NON_INTEGRATED) && (
            <Banner role="alert">
              {disableCodePrompt
                ? "It looks like we couldn't find your patient record. Verify your information or select 'Start Questionnaire' to start a new survey."
                : "We couldn't locate you in our system. Follow up with the front desk for a survey code. Thank you!"}
            </Banner>
          )}
          {error === ErrorType.GENERAL && (
            <Banner role="alert">
              There was an issue with your request. Please try again.
            </Banner>
          )}
          {error === ErrorType.NO_INITIAL_SURVEY && (
            <Banner role="alert">
              No initial screening survey found for {locationName}
            </Banner>
          )}
          {error === ErrorType.MULTIPLE_FOUND_INTEGRATED && (
            <Banner role="alert">
              We found multiple patient records with your information in our
              system. Please ask your healthcare team for help.
            </Banner>
          )}
          {error === ErrorType.NOT_FOUND && (
            <Banner role="alert">The provided code was invalid</Banner>
          )}
          <CopyContainer>
            <Typography theme="default" color="ciqBrand" variant="body1">
              Welcome to the CancerIQ platform, where you can access up-to-date
              information about your cancer risk. Let's get started by locating
              you in our system.
            </Typography>
          </CopyContainer>
          <Content
            onSubmit={handleSubmit}
            codeClick={handleCodeClick}
            nameDobClick={handleNameDobClick}
          />
        </Modal.Body>
        {contentType !== ContentType.FORM_SELECTION && (
          <Modal.Actions alignButtons="right">
            <CodeButtons>
              <Button
                size="large"
                type="button"
                theme="default"
                variant="text"
                color="default"
                disabled={isSubmitting}
                onClick={handleBackClick}
                startIcon={disableCodePrompt ? undefined : <ArrowBackIcon />}
              >
                {disableCodePrompt ? "Cancel" : "Back to Sign In"}
              </Button>
              <Button
                size="large"
                type="submit"
                color="default"
                theme="default"
                variant="contained"
                disabled={isSubmitting}
                onClick={delegateFormSubmit}
              >
                Sign In
              </Button>
            </CodeButtons>
            {showStartQuestionnaireBtn && (
              <QuestionnaireButton>
                <Button
                  color="default"
                  theme="default"
                  size="large"
                  variant="contained"
                  onClick={startQuestionnaire}
                >
                  Start Questionnaire
                </Button>
              </QuestionnaireButton>
            )}
          </Modal.Actions>
        )}
      </Modal>
    </>
  );
}
